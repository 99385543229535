import React from 'react'
import Address from 'src/components/Address'
import Background from 'src/components/Background'
import Details from 'src/components/Details'
import Fellows from 'src/components/Fellows'
import Footer from 'src/components/Footer'
import Header from 'src/components/Header'
import Intro from 'src/components/Intro'
import { Box, Container, CssBaseline, Grid } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const theme = createTheme({
    typography: {
        fontFamily: [
            'Epilogue',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    shape: {
        borderRadius: 16
    }
})

const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Header />
            <Box component="main">
                <Intro />
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={7} lg={7}>
                            <Background sx={{ mb: { xs: 4, md: 6 } }} />
                            <Details sx={{ mb: { xs: 4, md: 6 } }} />
                            <Address sx={{ mb: { xs: 4, md: 6 } }} />
                        </Grid>
                        <Grid item xs={12} md={5} lg={5}>
                            <Box sx={{ position: 'relative', height: '100%' }}>
                                <Fellows sx={{ position: 'sticky', top: '1rem', mb: { xs: 4, md: 6 } }} />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Footer />
        </ThemeProvider>
    )
}

export default App
